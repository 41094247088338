<template>
  <div class="event" v-padding:top="46">
    <!-- 头部 -->
    <van-nav-bar
      title="事件"
      left-arrow
      @click-left="goBack"
      fixed
    ></van-nav-bar>
    <!-- 头部end -->

    <!-- 1疑似人为干扰采样环境 -->
    <!-- 无 -->

    <!-- 2疑似修改分析仪器关键参数 -->
    <div v-if="evidenceData.eventType == 2">
      <!-- <van-cell-group title="变化前">
        <van-cell
          title="时间"
          :value="evidenceData.evidence2.before.changeTime"
        />
        <van-cell
          title="仪表名称"
          :value="evidenceData.evidence2.before.devicename"
        />
        <van-cell
          title="参数名称"
          :value="evidenceData.evidence2.before.dmParamname"
        />
        <van-cell
          title="参数标准值"
          :value="
            evidenceData.evidence2.before.dmStandardFloor +
              ' - ' +
              evidenceData.evidence2.before.dmStandardCeiling
          "
        />
        <van-cell
          title="参数值"
          :value="
            evidenceData.evidence2.before.dmValue
              ? evidenceData.evidence2.before.dmValue
              : '-'
          "
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence2.before.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence2.before.pstandardFloor +
              ' - ' +
              evidenceData.evidence2.before.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence2.before.pvalue
              ? evidenceData.evidence2.before.pvalue
              : '-'
          "
        />
      </van-cell-group> -->
      <van-cell-group title="事件信息">
        <van-cell
          title="时间"
          :value="evidenceData.evidence2.after.changeTime"
        />
        <van-cell
          title="仪表名称"
          :value="evidenceData.evidence2.after.devicename"
        />
        <van-cell
          title="参数名称"
          :value="evidenceData.evidence2.after.dmParamname"
        />
        <van-cell
          title="参数标准值"
          :value="
            evidenceData.evidence2.after.dmStandardFloor +
            ' - ' +
            evidenceData.evidence2.after.dmStandardCeiling
          "
        />
        <van-cell
          title="参数值"
          :value="
            evidenceData.evidence2.after.dmValue
              ? evidenceData.evidence2.after.dmValue
              : '-'
          "
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence2.after.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence2.after.pstandardFloor +
            ' - ' +
            evidenceData.evidence2.after.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence2.after.pvalue
              ? evidenceData.evidence2.after.pvalue
              : '-'
          "
        />
      </van-cell-group>
    </div>

    <!-- 3疑似违规生产 -->
    <!-- 无 -->

    <!-- 4疑似长时间关停治理设施 -->
    <!-- 无 -->

    <!-- 5疑似污染防治设施不正常运行 -->
    <!-- 无 -->

    <!-- 6疑似浓度超标违规 -->
    <div v-if="evidenceData.eventType == 6">
      <!-- <van-cell-group title="变化前">
        <van-cell
          title="时间"
          :value="evidenceData.evidence6.before.changeTime"
        />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence6.before.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence6.before.pstandardFloor +
              ' - ' +
              evidenceData.evidence6.before.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence6.before.pvalue
              ? evidenceData.evidence6.before.pvalue
              : '-'
          "
        />
      </van-cell-group> -->
      <van-cell-group title="事件信息">
        <!-- <van-cell title="所属企业" :value="this.$route.query.enterpriseName" />
        <van-cell title="站点名称" :value="this.$route.query.pointName" /> -->
        <van-cell
          title="数据时间"
          :value="evidenceData.evidence6.after.changeTime"
        />
        <van-cell title="事件时间" :value="evidenceData.eventTime" />
        <van-cell title="事件级别">
          <van-tag type="danger" v-if="$route.query.eventLevel == 1"
            >一级事件</van-tag
          >
          <van-tag type="warning" v-if="$route.query.eventLevel == 2"
            >二级事件</van-tag
          >
          <van-tag type="primary" v-if="$route.query.eventLevel == 3"
            >三级事件</van-tag
          >
          <van-tag v-if="$route.query.eventLevel == 4">巡查事件</van-tag>
        </van-cell>
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence6.after.pname"
        />
        <van-cell
          title="监测因子标准值"
          :value="
            evidenceData.evidence6.after.pstandardFloor +
            ' - ' +
            evidenceData.evidence6.after.pstandardCeiling
          "
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence6.after.pvalue
              ? evidenceData.evidence6.after.pvalue
              : '-'
          "
        />
        <van-cell
          title="数据类型"
          :value="
            $route.query.type == '2031'
              ? '日数据'
              : $route.query.type == '2061'
              ? '小时数据'
              : '-'
          "
        />
      </van-cell-group>
    </div>

    <!-- 7疑似总量超量违规 -->
    <!-- 无 -->

    <!-- 8疑似非法进入站房 -->
    <!-- 无 -->

    <!-- 9监测数据缺失 -->
    <div v-if="evidenceData.eventType == 9">
      <van-notice-bar wrapable :scrollable="false">
        企业：{{ evidenceData.evidence9.enterpriseName }}
        <br />
        站点：{{ evidenceData.evidence9.pointName }}
        <br />
        预警内容：未按时上报
        {{ evidenceData.evidence9.dataTime }}
        日数据。
      </van-notice-bar>
    </div>

    <!-- 10监测数据连续零值 -->
    <div v-if="evidenceData.eventType == 10">
      <van-cell-group title="事件信息">
        <van-cell title="事件时间" :value="evidenceData.eventTime" />
        <van-cell title="事件说明" :value="$route.query.describe" />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence10.divisorName"
        />
      </van-cell-group>
    </div>
    <!-- <ve-line
        heigth="300px"
        :data="chartData10"
        :settings="settings10"
        :extend="extend10"
      ></ve-line> -->
    <!-- 11监测数据连续不变 -->
    <div v-if="evidenceData.eventType == 11">
      <van-cell-group title="事件信息">
        <van-cell title="事件时间" :value="evidenceData.eventTime" />
        <van-cell title="事件说明" :value="$route.query.describe" />
        <van-cell
          title="监测因子名称"
          :value="evidenceData.evidence11.divisorName"
        />
        <van-cell
          title="监测因子值"
          :value="
            evidenceData.evidence11.divisorValue
              ? evidenceData.evidence11.divisorValue
              : '-'
          "
        />
      </van-cell-group>
      <!-- <ve-line
        heigth="300px"
        :data="chartData11"
        :settings="settings11"
        :extend="extend11"
      ></ve-line> -->
    </div>

    <!-- 12数采仪断线 -->
    <div v-if="evidenceData.eventType == 12">
      <van-notice-bar wrapable :scrollable="false">
        企业：{{ evidenceData.evidence12.enterpriseName }}<br />
        站点：{{ evidenceData.evidence12.pointName }} <br />
        预警内容：{{ evidenceData.evidence12.dataTime }}出现数采仪断线情况。
      </van-notice-bar>
    </div>

    <!-- 13监测值陡升陡降 -->
    <div class="whitebg" v-if="evidenceData.eventType == 13">
      <ve-line
        heigth="300px"
        :data="chartData13"
        :settings="settings13"
        :extend="extend13"
      ></ve-line>
    </div>

    <!-- 14仪表设备断线 -->
    <!-- 无 -->

    <!-- 15仪表设备故障 -->
    <div v-if="evidenceData.eventType == 15">
      <van-notice-bar wrapable :scrollable="false">
        企业：{{ evidenceData.evidence15.enterpriseName }}<br />
        站点：{{ evidenceData.evidence15.pointName }}<br />
        预警内容：{{ evidenceData.evidence15.dataTime }}出现{{
          evidenceData.evidence15.deviceName
        }}仪表设备故障情况
      </van-notice-bar>
    </div>
    <!-- 16运维商逾期运维 -->
    <div v-if="evidenceData.eventType == 16">
      <van-notice-bar wrapable :scrollable="false">
        运维商：{{ evidenceData.evidence16.operationsName }} <br />
        企业：{{ evidenceData.evidence16.enterpriseName }}<br />
        预警内容：运维商上周未对企业{{
          evidenceData.evidence16.pointName
        }}站点进行运维
      </van-notice-bar>
    </div>
    <!-- 17站房温度过高 -->
    <div v-if="evidenceData.eventType == 17">
      <van-notice-bar wrapable :scrollable="false">
        企业：{{ evidenceData.evidence17.enterpriseName }}<br />
        站点：{{ evidenceData.evidence17.pointName }}<br />
        预警内容：{{ evidenceData.evidence17.dataTime }}出现 站房温度过高情况。
      </van-notice-bar>
    </div>
    <!-- 18站房温度过低 -->
    <div v-if="evidenceData.eventType == 18">
      <van-notice-bar wrapable :scrollable="false">
        企业：{{ evidenceData.evidence18.enterpriseName }}<br />
        站点：{{ evidenceData.evidence18.pointName }} <br />
        预警内容：{{ evidenceData.evidence18.dataTime }}出现 站房温度过低情况。
      </van-notice-bar>
    </div>
    <!-- 图片回显 -->
    <!-- <div v-if="solveData.setp0.imageId">
      <van-cell-group title="事件附件">
        <div style="text-align:center">
          <img
            @click="showPreview"
            style="max-width:100%;max-height:100px;margin:0 auto;vertical-align: top;"
            :src="
              `${$api.base.api}/sys/file/viewMongdb/${solveData.setp0.imageId}`
            "
          />
        </div>
      </van-cell-group>
    </div> -->
    <div v-if="solveData.setp2.imageId">
      <van-cell-group title="企业处理附件">
        <div style="text-align: center">
          <img
            @click="showPreview"
            style="
              max-width: 100%;
              max-height: 100px;
              margin: 0 auto;
              vertical-align: top;
            "
            :src="`${$api.base.api}/sys/file/viewMongdb/${solveData.setp2.imageId}`"
          />
        </div>
      </van-cell-group>
    </div>

    <!-- 图片回显end -->
    <!-- 事件处理 -->
    <van-cell-group title="事件处理" v-if="rejectList == ''">
      <!-- <van-cell title="预警时间" :value="$route.query.warningTime" />
      <van-cell title="事件级别">
        <van-tag type="danger" v-if="$route.query.eventLevel == 1"
          >一级事件</van-tag
        >
        <van-tag type="warning" v-if="$route.query.eventLevel == 2"
          >二级事件</van-tag
        >
        <van-tag type="primary" v-if="$route.query.eventLevel == 3"
          >三级事件</van-tag
        >
        <van-tag v-if="$route.query.eventLevel == 4">巡查事件</van-tag>
      </van-cell> -->

      <!-- <van-cell title="事件说明" :value="$route.query.describe" /> -->
      <van-cell title="企业处理结果" :value="solveData.setp2.content" />
      <van-cell title="企业处理原因" :value="solveData.setp2.reasonName" />
      <van-cell title="处理结果" class="address-cell">
        <van-radio-group v-model="isAgree" direction="horizontal">
          <van-radio :name="1">同意</van-radio>
          <van-radio
            :name="0"
            :disabled="
              solveData.setp2Reject != '' && solveData.setp2Reject.length > 2
            "
            >驳回</van-radio
          >
          <van-radio :name="2">超标查处</van-radio>
          <span
            v-if="
              solveData.setp2Reject != '' && solveData.setp2Reject.length > 2
            "
            style="color: red; font-size: 10px"
            >已到达最多驳回次数，无法驳回</span
          >
        </van-radio-group>
      </van-cell>
      <van-cell
        v-if="isAgree == '2'"
        title="是否扣分"
        :value="isDeductMarks ? '将扣除企业评分 ' + mark + ' 分' : '本次不扣分'"
      >
        <template #right-icon>
          <van-switch v-model="isDeductMarks" size="24" />
        </template>
      </van-cell>
      <van-cell title="附件">
        <div class="file">
          <div
            class="file-item"
            v-for="(item, index) in defaultFileList"
            :key="index"
          >
            <img
              class="image"
              lazy-load
              @click.stop="showPreview"
              :src="`${$api.base.file}/` + encodeURIComponent(item.ossFileName)"
            />
          </div>
        </div>

        <div v-for="(item, index) in otherFileList" :key="index">
          <div @click="noticeFile(item)">
            {{ item.name }}
          </div>
        </div>
      </van-cell>
      <van-field
        v-model="remark"
        rows="2"
        autosize
        label="处理意见"
        type="textarea"
        maxlength="100"
        placeholder="请输入处理结果"
        show-word-limit
      />

      <van-button type="info" :loading="submitLoading" block @click="epaReply"
        >提交处理结果</van-button
      >
    </van-cell-group>
    <van-tabs v-if="rejectList.length > 0">
      <van-tab title="事件处理">
        <!-- <van-cell title="预警时间" :value="$route.query.warningTime" />
      <van-cell title="事件级别">
        <van-tag type="danger" v-if="$route.query.eventLevel == 1"
          >一级事件</van-tag
        >
        <van-tag type="warning" v-if="$route.query.eventLevel == 2"
          >二级事件</van-tag
        >
        <van-tag type="primary" v-if="$route.query.eventLevel == 3"
          >三级事件</van-tag
        >
        <van-tag v-if="$route.query.eventLevel == 4">巡查事件</van-tag>
      </van-cell> -->

        <!-- <van-cell title="事件说明" :value="$route.query.describe" /> -->
        <van-cell title="企业处理结果" :value="solveData.setp2.content" />
        <van-cell title="企业处理原因" :value="solveData.setp2.reasonName" />
        <van-cell title="处理结果" class="address-cell">
          <van-radio-group v-model="isAgree" direction="horizontal">
            <van-radio :name="1">同意</van-radio>
            <van-radio
              :name="0"
              :disabled="
                solveData.setp2Reject != '' && solveData.setp2Reject.length > 2
              "
              >驳回</van-radio
            >
            <van-radio :name="2">超标查处</van-radio>
            <span
              v-if="
                solveData.setp2Reject != '' && solveData.setp2Reject.length > 2
              "
              style="color: red; font-size: 10px"
              >已到达最多驳回次数，无法驳回</span
            >
          </van-radio-group>
        </van-cell>
        <van-cell
          v-if="isAgree == '1' || isAgree == '2'"
          title="是否扣分"
          :value="
            isDeductMarks ? '将扣除企业评分 ' + mark + ' 分' : '本次不扣分'
          "
        >
          <template #right-icon>
            <van-switch v-model="isDeductMarks" size="24" />
          </template>
        </van-cell>
        <van-cell title="附件">
          <div class="file">
            <div
              class="file-item"
              v-for="(item, index) in defaultFileList"
              :key="index"
            >
              <img
                class="image"
                lazy-load
                @click.stop="showPreview"
                :src="
                  `${$api.base.file}/` + encodeURIComponent(item.ossFileName)
                "
              />
            </div>
          </div>

          <div v-for="(item, index) in otherFileList" :key="index">
            <div @click="noticeFile(item)">
              {{ item.name }}
            </div>
          </div>
        </van-cell>
        <van-field
          v-model="remark"
          rows="2"
          autosize
          label="处理意见"
          type="textarea"
          maxlength="100"
          placeholder="请输入处理结果"
          show-word-limit
        />

        <van-button type="info" :loading="submitLoading" block @click="epaReply"
          >提交处理结果</van-button
        ></van-tab
      >
      <van-tab title="驳回记录">
        <div v-if="rejectList.length > 0">
          <div v-for="(item, index) in rejectList" :key="index">
            <div class="reject">
              <van-steps direction="vertical" :active="1">
                <van-step>
                  <van-row>
                    <van-col span="12" style="font-weight: bold">
                      企业处理
                    </van-col>
                    <van-col span="12"
                      ><span class="user">{{ item.name }}</span></van-col
                    >
                    <van-col span="12" style="margin: 10px 0">{{
                      item.dataTime
                    }}</van-col>
                    <van-col span="24" style="margin-bottom: 10px">{{
                      item.content
                    }}</van-col>
                    <van-col span="24">
                      <div class="file">
                        <div
                          class="file-item"
                          v-for="(itemC, index) in item.minioFiles"
                          :key="index"
                        >
                          <img
                            class="image"
                            lazy-load
                            @click.stop="showPreview"
                            :src="
                              `${$api.base.file}/` +
                              encodeURIComponent(itemC.ossFileName)
                            "
                          />
                        </div>
                      </div>
                      <div
                        v-for="(itemC, index) in item.minioFiles"
                        :key="index"
                      >
                        <div
                          @click="noticeFile(itemC)"
                          v-show="
                            /\.(ppt|pptx|xlsx|xls|doc|pdf|docx)$/.test(
                              itemC.ossFileName
                            )
                          "
                        >
                          {{ itemC.fileName }}
                        </div>
                      </div></van-col
                    >
                  </van-row>
                </van-step>
                <van-step>
                  <van-row>
                    <van-col span="12" style="font-weight: bold">
                      执法人员处理
                    </van-col>
                    <van-col span="12">
                      <span class="rejectUser"> {{ item.turnDownUser }}</span>
                    </van-col>
                    <van-col span="12" style="margin: 10px 0">
                      {{ enforceReject[index].dataTime }}
                    </van-col>
                    <van-col span="24" style="margin-bottom: 10px">
                      {{ item.turnDownContent }}
                    </van-col>
                    <van-col span="12">
                      处理结果
                      <van-tag type="danger" round>{{
                        enforceReject[index].result
                      }}</van-tag>
                    </van-col>
                    <van-col span="12">
                      <span class="score">
                        是否扣分
                        <van-tag
                          round
                          type="danger"
                          v-if="enforceReject[index].isDeductMarks == 1"
                          >是</van-tag
                        >
                        <van-tag
                          round
                          type="primary"
                          v-if="enforceReject[index].isDeductMarks == 0"
                          >否</van-tag
                        >
                      </span>
                    </van-col>
                    <van-divider />
                  </van-row>
                </van-step>
              </van-steps>
            </div>
          </div>
        </div>
        <van-empty description="暂无数据" v-else /> </van-tab
    ></van-tabs>
    <!-- 事件处理end -->
  </div>
</template>

<script>
import { Toast, ImagePreview } from "vant";

export default {
  data() {
    return {
      rejectList: [], // 驳回记录--企业处理
      enforceReject: [], // 驳回记录--执法人员处理
      defaultFileList: [],
      otherFileList: [],
      submitLoading: false,
      isAgree: 1,
      isDeductMarks: false,
      solveData: {
        setp: 0,
        setp0: {
          dataTime: "",
          describe: "",
          eventType: "",
          leve: "",
          source: "",
        },
        setp1: {
          address: "",
          contactName: "",
          contactTel: "",
          dataTime: "",
          enterprise: "",
          operationEnterprise: "",
        },
        setp2: {
          content: "",
          dataTime: "",
          enterprise: "",
          imageId: "",
          name: "",
          tel: "",
        },
        setp2Reject: [], // 驳回
        setp3: {
          dataTime: "",
          isDeductMarks: "",
          name: "",
          remark: "",
          result: "",
        },
        setp4: {
          dataTime: "",
          millisecond: "",
        },
      },
      uploadedFile: "",
      remark: "",
      busId: "",
      eventInfo: {},
      file: [],
      evidenceData: {},
      chartData10: {
        columns: [],
        rows: [],
      },
      settings10: {
        digit: 4,
        xAis: {
          inverse: true,
        },
        labelMap: {},
      },
      extend10: {
        tooltip: {
          formatter(params) {
            const list = [];
            let listItem = "";
            for (let i = 0; i < params.length; i++) {
              let value = "-";
              if (params[i].value[1]) {
                value = params[i].value[1];
              }
              list.push(
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                  params[i].color +
                  ';margin-right: 5px;border-radius: 50%;}"></i><span style="display:inline-block;">' +
                  params[i].seriesName +
                  "</span>：" +
                  value
              );
            }
            listItem = list.join("<br>");
            return (
              '<div class="showBox"><span>' +
              params[0].value[0] +
              "</span><br>" +
              listItem +
              "</div>"
            );
          },
        },
      },
      chartData11: {
        columns: [],
        rows: [],
      },
      settings11: {
        digit: 4,
        xAis: {
          inverse: true,
        },
        labelMap: {},
      },
      extend11: {
        tooltip: {
          formatter(params) {
            const list = [];
            let listItem = "";
            for (let i = 0; i < params.length; i++) {
              let value = "-";
              if (params[i].value[1]) {
                value = params[i].value[1];
              }
              list.push(
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                  params[i].color +
                  ';margin-right: 5px;border-radius: 50%;}"></i><span style="display:inline-block;">' +
                  params[i].seriesName +
                  "</span>：" +
                  value
              );
            }
            listItem = list.join("<br>");
            return (
              '<div class="showBox"><span>' +
              params[0].value[0] +
              "</span><br>" +
              listItem +
              "</div>"
            );
          },
        },
      },
      evidence10TableData: [],
      evidence10columns: [],
      chartData13: {
        columns: [],
        rows: [],
      },
      settings13: {
        digit: 4,
        xAis: {
          inverse: true,
        },
        labelMap: {},
      },
      extend13: {
        tooltip: {
          formatter(params) {
            const list = [];
            let listItem = "";
            for (let i = 0; i < params.length; i++) {
              let value = "-";
              if (params[i].value[1]) {
                value = params[i].value[1];
              }
              list.push(
                '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                  params[i].color +
                  ';margin-right: 5px;border-radius: 50%;}"></i><span style="display:inline-block;">' +
                  params[i].seriesName +
                  "</span>：" +
                  value
              );
            }
            listItem = list.join("<br>");
            return (
              '<div class="showBox"><span>' +
              params[0].value[0] +
              "</span><br>" +
              listItem +
              "</div>"
            );
          },
        },
      },
    };
  },
  watch: {
    isAgree(newVal) {
      if (newVal == "2") {
        this.isDeductMarks = true;
      } else {
        this.isDeductMarks = false;
      }
    },
  },
  computed: {
    mark() {
      if (this.$route.query.eventLevel == 1) {
        return 3;
      }
      if (this.$route.query.eventLevel == 2) {
        return 2;
      }
      if (this.$route.query.eventLevel == 3) {
        return 1;
      }
      return 0;
    },
  },
  methods: {
    // 事件提醒
    noticeFile() {
      Toast.fail("不支持在线查看，请到PC端下载");
    },
    // 图片预览
    showPreview(e) {
      ImagePreview([e.target.src]);
    },
    // 获取事件报告
    getReportData() {
      this.solveData = {
        setp: 0,
        setp0: {
          dataTime: "",
          describe: "",
          eventType: "",
          leve: "",
          source: "",
        },
        setp1: {
          address: "",
          contactName: "",
          contactTel: "",
          dataTime: "",
          enterprise: "",
          operationEnterprise: "",
        },
        setp2: {
          content: "",
          dataTime: "",
          enterprise: "",
          imageId: "",
          name: "",
          tel: "",
        },
        setp3: {
          dataTime: "",
          isDeductMarks: "",
          name: "",
          remark: "",
          result: "",
        },
        setp4: {
          dataTime: "",
          millisecond: "",
        },
      };
      if (this.$route.query.eventLevel == 4) {
        // 远程巡查事件获取事件报告
        const data = {
          eventId: this.$route.query.id,
        };
        this.$api.event.getInspectReport(data).then((res) => {
          if (res.data.state == 0) {
            this.solveData = res.data.data;
            this.busId = res.data.data.setp2.imageId;
          }
        });
      } else {
        // 普通事件获取事件报告
        const data = {
          eventId: this.$route.query.id,
        };
        this.$api.event.getReport(data).then((res) => {
          if (res.data.state == 0) {
            this.solveData = res.data.data;
            this.busId = res.data.data.setp2.imageId;
          }
        });
      }
    },
    // 事件类型10获取图表数据
    getChartData10() {
      const data = {
        pointId: this.evidenceData.evidence10.pointId,
        cn: this.evidenceData.evidence10.cn,
      };
      this.$api.event
        .getHeadDataByPonitid(data)
        .then((res) => {
          if (res.data.state == 0) {
            const _data = res.data.data || [];
            const tempColumns = [];
            let labelMap = {};
            _data.forEach((element) => {
              if (element.title == "时间") {
                tempColumns.push("dataTime");
                labelMap = {
                  dataTime: "时间",
                };
              } else {
                tempColumns.push(element.field);
                labelMap[element.field] = element.title;
              }
            });
            this.chartData10.columns = tempColumns;
            this.settings10.labelMap = labelMap;
          }
        })
        .then(() => {
          const params = {
            index: 1,
            size: 10000,
            pointId: this.evidenceData.evidence10.pointId,
            eventType: 10,
            eventTime: this.$route.query.warningTime,
            timeType: 3,
          };
          this.$api.event.getDataByPoint(params).then((res) => {
            this.chartData10.rows = res.data.data.list || [];
          });
        })
        .catch((err) => {
          throw err;
        });
    },
    // 事件类型11获取图表数据
    getChartData11() {
      const data = {
        pointId: this.evidenceData.evidence11.pointId,
        cn: this.evidenceData.evidence11.cn,
      };
      this.$api.event
        .getHeadDataByPonitid(data)
        .then((res) => {
          if (res.data.state == 0) {
            const _data = res.data.data || [];
            const tempColumns = [];
            let labelMap = {};
            _data.forEach((element) => {
              if (element.title == "时间") {
                tempColumns.push("dataTime");
                labelMap = {
                  dataTime: "时间",
                };
              } else {
                tempColumns.push(element.field);
                labelMap[element.field] = element.title;
              }
            });
            this.chartData11.columns = tempColumns;
            this.settings11.labelMap = labelMap;
          }
        })
        .then(() => {
          const params = {
            index: 1,
            size: 10000,
            pointId: this.evidenceData.evidence11.pointId,
            eventType: 11,
            eventTime: this.$route.query.warningTime,
            timeType: 3,
          };
          this.$api.event.getDataByPoint(params).then((res) => {
            this.chartData11.rows = res.data.data.list || [];
          });
        });
    },
    // 事件类型13获取图表数据
    getChartData13() {
      const data = {
        pointId: this.evidenceData.evidence13.pointId,
        cn: this.evidenceData.evidence13.cn,
      };
      this.$api.event
        .getHeadDataByPonitid(data)
        .then((res) => {
          if (res.data.state == 0) {
            const _data = res.data.data || [];
            const tempColumns = [];
            let labelMap = {};
            _data.forEach((element) => {
              if (element.title == "时间") {
                tempColumns.push("dataTime");
                labelMap = {
                  dataTime: "时间",
                };
              } else {
                tempColumns.push(element.field);
                labelMap[element.field] = element.title;
              }
            });
            this.chartData13.columns = tempColumns;
            this.settings13.labelMap = labelMap;
          }
        })
        .then(() => {
          const params = {
            index: 1,
            size: 10000,
            pointId: this.evidenceData.evidence13.pointId,
            eventType: 13,
            eventTime: this.$route.query.warningTime,
            timeType: 3,
          };
          this.$api.event.getDataByPoint(params).then((res) => {
            this.chartData13.rows = res.data.data.list || [];
          });
        });
    },
    // 根据事件id查询证据链数据
    getEvidenceByEventId() {
      if (this.$route.query.eventLevel == 4) {
        // 远程巡查事件没有证据链可查询
        return false;
      }
      const data = {
        eventId: String(this.$route.query.id),
      };
      this.$api.event.getEvidenceByEventId(data).then((res) => {
        if (res.data.state == 0) {
          this.evidenceData = res.data.data;
          switch (this.evidenceData.eventType) {
            case 1:
              this.busId = this.evidenceData.evidence1.busId;
              break;
            case 2:
              this.busId = this.evidenceData.evidence2.busId;
              break;
            case 6:
              this.busId = this.evidenceData.evidence6.busId;
              break;
            // case 10:
            //   this.getChartData10();
            //   break;
            // case 11:
            //   this.getChartData11();
            //   break;
            case 13:
              this.getChartData13();
              break;
            default:
              this.busId = "";
              break;
          }
        }
      });
    },
    deleteFile() {
      this.uploadedFile = "";
    },
    afterRead(file) {
      const data = new FormData();
      if (file.length) {
        file.forEach((element) => {
          data.append("file", element.file);
        });
      }
      // data.append("file", file.file);
      this.$api.common.uploadFileApi(data).then((res) => {
        this.uploadedFile = res.data.data;
      });
    },
    // 查询事件处理
    getEventReportByEventId() {
      const data = {
        eventId: this.$route.query.id,
      };
      this.$api.event.getReport(data).then((res) => {
        if (res.data.state == 0) {
          this.solveData = res.data.data;
          this.rejectList = res.data.data.setp2Reject;
          this.enforceReject = res.data.data.setp3Reject;
          const resourcesData = {
            type: 1,
            resourcesId: Number(this.$route.query.id),
          };
          this.$api.event
            .postMinioFilesByResourcesId(resourcesData)
            .then((params) => {
              if (params.data.state == 0) {
                const fileArr = params.data.data || [];
                for (let i = 0; i < fileArr.length; i++) {
                  const element = fileArr[i];
                  if (
                    /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(
                      element.ossFileName
                    )
                  ) {
                    this.defaultFileList.push({
                      name: element.fileName,
                      url: element.url,
                      ossFileName: element.ossFileName,
                    });
                  } else {
                    this.otherFileList.push({
                      name: element.fileName,
                      url: element.url,
                      ossFileName: element.ossFileName,
                    });
                  }
                }
              }
            });
        }
      });
    },
    // 处理事件
    epaReply() {
      this.submitLoading = true;
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      // 同意&驳回：不扣分                超标查处：视情况扣分
      // 同意&超标查处备注：agreeRemark   驳回备注：rejectRemark
      const data = {
        eventId: this.$route.query.id,
        isAgree: this.isAgree,
        isDeductMarks: this.isAgree != 2 ? "0" : this.isDeductMarks ? "1" : "0",
        agreeRemark: this.isAgree == 0 ? "" : this.remark,
        rejectRemark: this.isAgree == 0 ? this.remark : "",
      };
      this.$api.event.postEpaReply(data).then((res) => {
        if (res.data.state == 0) {
          Toast.success("提交成功");
          this.goBack();
        }
      });
    },
  },
  created() {
    this.getEvidenceByEventId();
    this.getReportData();
    this.getEventReportByEventId();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "Event") {
      this.removeKeepAlive("Event");
    }
    next();
  },
};
</script>

<style lang="less" spcoed>
.address-cell {
  .van-cell__value {
    min-width: 70%;
    span {
      display: inline-block;
      text-align: left;
      word-break: break-all;
    }
  }
}
</style>
