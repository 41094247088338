<template>
  <div class="event" v-padding:top="46">
    <van-nav-bar title="事件" fixed>
      <template #left v-if="userType == 2 || userType == 3">
        <van-icon
          name="iconfont iconfont iconqiehuan"
          size="24"
          color="#C2D3DF"
          @click="changeUser"
        />
      </template>
      <div slot="right">
        <van-icon
          name="iconfont iconfont iconfangdajing"
          size="18"
          @click="onShowPopup"
        />
      </div>
    </van-nav-bar>
    <panel :hidden-header="true">
      <div class="statistics">
        <div
          class="statistics-item"
          v-for="(item, index) in eventStatistics"
          :key="index"
          :style="{ background: item.color }"
        >
          <div class="statistics-item-value">{{ item.value }}</div>
          <div class="statistics-item-label">{{ item.label }}</div>
        </div>
      </div>
    </panel>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getEventList"
      >
        <event-panel
          v-for="(item, index) in eventList"
          @click="toProcess(item)"
          :key="index"
          :index="index + 1"
          :reject="item.isReject == 0 ? false : true"
          :title="
            eventTypeList[item.eventType]
              ? eventTypeList[item.eventType]
              : '远程巡查'
          "
          :enterprise="item.enterpriseName"
          :point="item.pointName ? item.pointName : '-'"
          :time="item.warningTime"
        >
          <van-tag
            slot="extra"
            plain
            type="success"
            size="small"
            style="margin-right: 10px"
            v-if="item.type == '2031'"
          >
            日
          </van-tag>
          <van-tag
            type="primary"
            slot="extra"
            plain
            size="small"
            style="margin-right: 10px"
            v-if="item.type == '2061'"
          >
            {{
              item.type == "2061"
                ? "小时"
                : item.type == "2051"
                ? "分钟"
                : item.type == "2011"
                ? "日"
                : "-"
            }}
          </van-tag>
          <!--<van-tag
            type="primary"
            slot="extra"
            plain
            size="small"
            style="margin-right: 10px"
            v-if="item.type == '2051'"
          >
            分钟
          </van-tag>
          <van-tag
            type="primary"
            slot="extra"
            plain
            size="small"
            style="margin-right: 10px"
            v-if="item.type == '2011'"
          >
            实时
          </van-tag> -->
          <van-tag slot="extra" size="large" v-if="item.setp == 0">
            未推送
          </van-tag>
          <van-tag
            type="primary"
            slot="extra"
            size="large"
            v-if="item.setp == 1"
          >
            已推送
          </van-tag>
          <van-tag
            type="warning"
            slot="extra"
            size="large"
            v-if="item.setp == 2"
          >
            处理中
          </van-tag>
          <van-tag
            type="success"
            slot="extra"
            size="large"
            v-if="item.setp == 3"
          >
            已办结
          </van-tag>
          <van-tag
            type="danger"
            slot="extra"
            size="large"
            v-if="item.setp == 4"
          >
            超标查处
          </van-tag>
        </event-panel>
      </van-list>
    </van-pull-refresh>
    <van-popup
      v-model="popupShow"
      position="right"
      class="search-popup"
      @close="define"
    >
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="dropdownValue"
            :options="dropdownOption"
          />
        </van-dropdown-menu>
      </div>
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="dropdownValueSetp"
            :options="dropdownOptionSetp"
          />
        </van-dropdown-menu>
      </div>
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="dropdownValueReason"
            :options="dropdownOptionReason"
          />
        </van-dropdown-menu>
      </div>
      <div class="search">
        <van-field
          v-model="dateValue"
          placeholder="时间范围"
          @click="onShowCalendar"
          disabled
        >
          <van-icon
            name="iconfont iconfont iconrilirichengCRM"
            slot="right-icon"
            @click="onShowCalendar"
          />
        </van-field>
      </div>
      <div class="search">
        <van-field v-model="enterpriseName" placeholder="请输入企业名称" />
      </div>
      <div v-if="dropdownValue == 2">
        <div class="search">
          <van-dropdown-menu>
            <van-dropdown-item v-model="industryId" :options="IndustryList" />
          </van-dropdown-menu>
        </div>
        <div class="search">
          <van-radio-group v-model="pointTypeId" direction="horizontal">
            <van-radio name="0">全部</van-radio>
            <van-radio name="31">气类</van-radio>
            <van-radio name="32">水类</van-radio>
          </van-radio-group>
        </div>
        <div class="search">
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="divisorName"
              :options="factorList"
              :disabled="pointTypeId == 0"
            />
          </van-dropdown-menu>
        </div>
      </div>

      <van-row class="fix-bottom">
        <van-col span="24">
          <van-button type="primary" block @click="popupShow = false"
            >确定</van-button
          >
        </van-col>
      </van-row>
    </van-popup>
    <van-calendar
      type="range"
      color="#378aed"
      close-on-popstate
      :allow-same-day="true"
      v-model="showCalendar"
      title="时间范围"
      :min-date="new Date(2010, 0, 1)"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import eventPanel from "@/components/common/event-panel.vue"; // 事件面板
import panel from "@/components/common/panel.vue";
// 公共面板
export default {
  name: "Event",
  components: { panel, eventPanel },
  data() {
    return {
      divisorName: "",
      pointTypeId: "0",
      refreshing: false, // 下拉刷新
      loading: false, // 上拉加载loading
      finished: false, // 上拉加载完结loading
      current: 1, // 页码
      pagesize: 10, // 单页条数
      enterpriseName: "",
      beginTime:
        this.$moment().subtract(1, "months").format("YYYY-MM-DD") + " 00:00:00",
      endTime: this.$moment().format("YYYY-MM-DD") + " 23:59:59",
      dateValue: "",
      showCalendar: false,
      dropdownValue: 0,
      industryId: "",
      IndustryList: [],
      dropdownOption: [
        { text: "全部事件", value: 0 },
        { text: "一级事件", value: 1 },
        { text: "二级事件", value: 2 },
        { text: "三级事件", value: 3 },
      ],
      dropdownValueSetp: "",
      dropdownOptionSetp: [
        { text: "全部状态", value: "" },
        { text: "已推送", value: 1 },
        { text: "处理中", value: 2 },
        { text: "已完结", value: 3 },
      ],
      dropdownValueReason: "",
      dropdownOptionReason: [],
      factorList: [
        {
          text: "全部因子",
          value: "",
        },
      ],
      popupShow: false,
      eventList: [],
      eventStatistics: [
        {
          color: "#E05F38",
          label: "一级事件",
          value: 0,
        },
        {
          color: "#4EC294",
          label: "二级事件",
          value: 0,
        },
        {
          color: "#378AED",
          label: "三级事件",
          value: 0,
        },
        // {
        //   color: "#A6A6A6",
        //   label: "巡检事件",
        //   value: 0
        // }
      ],
      eventTypeList: {
        1: "人为干扰采样环境",
        2: "修改分析仪器关键参数",
        3: "违规生产",
        4: "长时间关停治理设施",
        5: "污染防治设施不正常运行",
        6: "浓度超标违规",
        7: "总量超量违规",
        8: "非法进入站房",
        9: "监测数据缺失",
        10: "监测数据连续零值",
        11: "监测数据连续不变",
        12: "数采仪断线",
        13: "监测值陡升陡降",
        14: "仪表设备断线",
        15: "仪表设备故障",
        16: "运维商逾期运维",
        17: "站房温度过高",
        18: "站房温度过低",
      },
      // 上方注释数据
      // item.eventType == 1
      //         ? '人为干扰采样环境'
      //         : item.eventType == 2
      //         ? '修改分析仪器关键参数'
      //         : item.eventType == 3
      //         ? '违规生产'
      //         : item.eventType == 4
      //         ? '长时间关停治理设施'
      //         : item.eventType == 5
      //         ? '污染防治设施不正常运行'
      //         : item.eventType == 6
      //         ? '浓度超标违规'
      //         : item.eventType == 7
      //         ? '总量超量违规'
      //         : item.eventType == 8
      //         ? '非法进入站房'
      //         : item.eventType == 9
      //         ? '监测数据缺失'
      //         : item.eventType == 10
      //         ? '监测数据连续零值'
      //         : item.eventType == 11
      //         ? '监测数据连续不变'
      //         : item.eventType == 12
      //         ? '数采仪断线'
      //         : item.eventType == 13
      //         ? '监测值陡升陡降'
      //         : item.eventType == 14
      //         ? '仪表设备断线'
      //         : item.eventType == 15
      //         ? '仪表设备故障'
      //         : item.eventType == 16
      //         ? '运维商逾期运维'
      //         : item.eventType == 17
      //         ? '站房温度过高'
      //         : item.eventType == 18
      //         ? '站房温度过低'
      //         : '远程巡查'
    };
  },
  computed: {
    userType() {
      return localStorage.getItem("userType");
    },
  },
  watch: {
    dropdownValue(newVal) {
      if (newVal) {
        this.divisorName = "";
        this.pointTypeId = "0";
      }
    },
    pointTypeId(newVal) {
      this.divisorName = "";
      if (newVal == 31) {
        this.factorList = [
          {
            text: "全部因子",
            value: "",
          },
          {
            text: "氮氧化物",
            value: "氮氧化物",
          },
          {
            text: "二氧化硫",
            value: "二氧化硫",
          },
          {
            text: "烟尘",
            value: "烟尘",
          },
          {
            text: "其他",
            value: "xxxx",
          },
        ];
      } else if (newVal == 32) {
        this.factorList = [
          {
            text: "全部因子",
            value: "",
          },
          {
            text: "cod",
            value: "cod",
          },
          {
            text: "氨氮",
            value: "氨氮",
          },
          {
            text: "总磷",
            value: "总磷",
          },
          {
            text: "总氮",
            value: "总氮",
          },
          {
            text: "重金属",
            value: "zzzz",
          },
        ];
      } else {
        this.factorList = [
          {
            text: "全部因子",
            value: "",
          },
        ];
      }
    },
  },
  methods: {
    getSysReason() {
      this.$api.event.getSysReason().then((res) => {
        if (res.data.state == 0) {
          const list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            this.dropdownOptionReason.push({
              text: list[i].name,
              value: list[i].id,
            });
          }
          this.dropdownOptionReason.unshift({
            text: "全部原因",
            value: "",
          });
        }
      });
    },
    // 获取行业
    getIndustryList() {
      this.$api.event.getIndustryListAll().then((res) => {
        if (res.status == 200) {
          const list = res.data.data;
          for (let i = 0; i < list.length; i++) {
            this.IndustryList.push({
              text: list[i].industryName,
              value: list[i].id,
            });
          }
          this.IndustryList.unshift({
            text: "全部行业",
            value: "",
          });
        }
      });
    },
    // 下拉刷新
    onRefresh() {
      this.current = 1;
      this.loading = true;
      this.eventList = [];
      const data = {
        index: this.current,
        size: this.pagesize,
        eventLeve: this.dropdownValue,
        beginTime: this.beginTime,
        endTime: this.endTime,
        setp: this.dropdownValueSetp,
        industryId: this.industryId,
        pointTypeId: this.pointTypeId == 0 ? " " : this.pointTypeId,
        divisorName: this.divisorName,
        userId: "",
        enterprseId: "",
        pointId: "",
        sysReasonId: this.dropdownValueReason,
      };
      this.$api.event
        .getEventList(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.eventStatistics[0].value = res.data.data.event1;
            this.eventStatistics[1].value = res.data.data.event2;
            this.eventStatistics[2].value = res.data.data.event3;
            this.eventList = res.data.data.page.records;
            if (this.current >= res.data.data.page.pages) {
              this.finished = true;
            } else {
              this.current++;
            }
          }
          this.refreshing = false;
          this.loading = false;
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
          this.refreshing = false;
          throw err;
        });
    },
    // 跳转事件处理页面
    toProcess(item) {
      // 1 环保局用户 2企业用户 3运维用户
      if (localStorage.getItem("userType") == 1 && item.setp == 2) {
        // 执法人员且状态为处理中，跳转执法人员处理页面
        this.$router.push({
          name: "EnforcementProcess",
          query: {
            id: item.id,
            eventType: item.eventType,
            eventLevel: item.leve,
            warningTime: item.warningTime,
            describe: item.describe,
            isReject: item.isReject,
            type: item.type,
            enterpriseName: item.enterpriseName,
            pointName: item.pointName,
          },
        });
      } else if (
        (localStorage.getItem("userType") == 2 && item.setp == 1) ||
        (localStorage.getItem("userType") == 3 && item.setp == 1)
      ) {
        // 企业用户且状态为已推送，跳转企业用户处理页面
        this.$router.push({
          name: "EnterpriseProcess",
          query: {
            id: item.id,
            eventType: item.eventType,
            eventLevel: item.leve,
            warningTime: item.warningTime,
            describe: item.describe,
            image: item.image,
            type: item.type,
            enterpriseName: item.enterpriseName,
            pointName: item.pointName,
          },
        });
      } else {
        // 都不是则跳转事件查看页面
        this.$router.push({
          name: "EventRead",
          query: {
            id: item.id,
            eventType: item.eventType,
            eventLevel: item.leve,
            warningTime: item.warningTime,
            describe: item.describe,
            image: item.image,
            type: item.type,
            enterpriseName: item.enterpriseName,
            pointName: item.pointName,
          },
        });
      }
    },
    // 日历确定事件
    onConfirm(date) {
      this.beginTime = this.$moment(date[0]).format("YYYY-MM-DD") + " 00:00:00";
      this.endTime = this.$moment(date[1]).format("YYYY-MM-DD") + " 23:59:59";
      this.dateValue =
        this.$moment(date[0]).format("YYYY-MM-DD") +
        " ~ " +
        this.$moment(date[1]).format("YYYY-MM-DD");
      this.showCalendar = false;
    },
    // 显示日历
    onShowCalendar() {
      this.showCalendar = true;
    },
    // 确定
    define() {
      this.finished = false;
      this.current = 1;
      this.eventList = [];
      this.getEventList();
      this.popupShow = false;
    },
    // 显示弹出层
    onShowPopup() {
      this.popupShow = true;
    },
    // 获取事件统计
    getEventList() {
      this.loading = true;
      const data = {
        index: this.current,
        size: this.pagesize,
        eventLeve: this.dropdownValue,
        beginTime: this.beginTime,
        endTime: this.endTime,
        enterprseName: this.enterpriseName,
        setp: this.dropdownValueSetp,
        industryId: this.industryId,
        pointTypeId: this.pointTypeId == 0 ? " " : this.pointTypeId,
        divisorName: this.divisorName,
        userId: "",
        enterprseId: "",
        pointId: "",
        sysReasonId: this.dropdownValueReason,
      };
      this.$api.event
        .getEventList(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.eventStatistics[0].value = res.data.data.event1;
            this.eventStatistics[1].value = res.data.data.event2;
            this.eventStatistics[2].value = res.data.data.event3;
            // this.eventStatistics[3].value = res.data.data.inspect;
            this.eventList.push(...res.data.data.page.records);
            if (this.current >= res.data.data.page.pages) {
              this.finished = true;
            } else {
              this.current++;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
          throw err;
        });
    },
  },
  mounted() {
    this.getEventList();
    this.getIndustryList();
    this.getSysReason();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "EnterpriseProcess" && to.name != "EnforcementProcess") {
      this.removeKeepAlive("Event");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.addKeepAlive("Event");
    });
  },
  activated() {
    this.current = 1;
    this.eventList = [];
    this.getEventList();
  },
};
</script>

<style></style>
